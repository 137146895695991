import React, { useState, useEffect } from 'react';
import { Button, Form, Input, message } from 'antd';
import axios from 'axios';

export const Mission = () => {
  const [isEditing, setIsEditing] = useState(false); // State to track if in edit mode
  const [description, setDescription] = useState(''); // Initial description value (fetched from backend)
  const [loading, setLoading] = useState(true); // Loading state while fetching data

  // Retrieve the accessToken from localStorage
  const accessToken = localStorage.getItem('accessToken');

  // Set the authorization header with the Bearer token
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  // Fetch the mission description from the backend when the component mounts (ID = 3)
  useEffect(() => {
    const fetchDescription = async () => {
      try {
        const response = await axios.get('api/backgrounds/6718d67fcf3fdbfca7bb2645', axiosConfig); // Fetch background with ID 3
        setDescription(response.data.description); // Assuming the API response has a "description" field
        setLoading(false);
      } catch (error) {
        console.error('Error fetching mission description:', error);
        message.error('Failed to load Mission description');
        setLoading(false);
      }
    };

    fetchDescription();
  }, [accessToken]); // Re-run if accessToken changes

  // Handle form submission for updating the mission description
  const onFinish = async (values) => {
    try {
      // Send the update to the backend where the name is always 'Mission' and description is updatable
      await axios.put('api/backgrounds/6718d67fcf3fdbfca7bb2645', {
        name: 'Mission', // Always set the name to "Mission"
        description: values.description, // Update the description from form input
      }, axiosConfig);
      setDescription(values.description); // Update the local description state
      setIsEditing(false); // Exit edit mode
      message.success('Mission updated successfully');
    } catch (error) {
      console.error('Error updating Mission description:', error);
      message.error('Failed to update Mission description');
    }
  };

  const handleEditClick = () => {
    setIsEditing(true); // Enter edit mode
  };

  const handleCancelClick = () => {
    setIsEditing(false); // Exit edit mode without saving
  };

  if (loading) {
    return <p>Loading Mission...</p>; // Show loading message while fetching
  }

  return (
    <div>
      <h2 className="text-3xl font-bold mb-6">Mission</h2>

      {isEditing ? (
        // Form for editing the mission description when in edit mode
        <Form
          name="missionForm"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{ description: description }} // Set the initial value of the text area
        >
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: 'Please input the Mission description!' }]}
          >
            <Input.TextArea rows={4} placeholder="Enter Mission description..." />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
            <Button
              type="default"
              onClick={handleCancelClick}
              style={{ marginLeft: '10px' }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      ) : (
        // Display the current mission description and an Edit button when not in edit mode
        <div>
          <p>{description}</p>
          <Button type="primary" onClick={handleEditClick}>
            Edit
          </Button>
        </div>
      )}
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import { Button, Form, Input, List, Upload, Typography, message, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

export const Export = () => {
  const [exportItems, setExportItems] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentName, setCurrentName] = useState('');
  const [currentDescription, setCurrentDescription] = useState('');
  const [currentImage, setCurrentImage] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  
  const accessToken = localStorage.getItem('accessToken');
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  useEffect(() => {
    const fetchExportItems = async () => {
      try {
        const response = await axios.get('api/exports', axiosConfig);
        setExportItems(response.data);
      } catch (error) {
        message.error('Failed to load export items');
        console.error(error);
      }
    };
  
    fetchExportItems();
  }, [accessToken]);

  const onFinish = async () => {
    console.log('Current Image:', currentImage);
    try {
      const updatedValue = {
        name: currentName,
        description: currentDescription,
      };
  
      // Prepare FormData to handle file upload
      const formData = new FormData();
      formData.append('name', currentName);
      formData.append('description', currentDescription);
  
      if (currentImage) {
        formData.append('img', currentImage); // Append the file if it exists
      }
  
      if (isEditing && editingIndex !== null) {
        const id = exportItems[editingIndex]._id;
        await axios.put(`api/exports/${id}`, formData, {
          ...axiosConfig,
          headers: {
            ...axiosConfig.headers,
            'Content-Type': 'multipart/form-data',
          },
        });
  
        const updatedItems = [...exportItems];
        updatedItems[editingIndex] = { ...updatedItems[editingIndex], ...updatedValue };
        setExportItems(updatedItems);
        message.success('Item updated successfully');
      } else {
        const response = await axios.post('api/exports', formData, {
          ...axiosConfig,
          headers: {
            ...axiosConfig.headers,
            'Content-Type': 'multipart/form-data',
          },
        });
        setExportItems([...exportItems, response.data]);
        message.success('Item added successfully');
      }
    } catch (error) {
      message.error('Failed to save item');
      console.error(error);
    }
  
    resetForm();
  };

  const handleEditClick = (index) => {
    setCurrentName(exportItems[index].name);
    setCurrentDescription(exportItems[index].description);
    setCurrentImage(null); // Reset image for editing
    setEditingIndex(index);
    setIsEditing(true);
    setIsModalVisible(true);
  };

  const handleDeleteClick = async (index) => {
    try {
      const id = exportItems[index]._id;
      await axios.delete(`api/exports/${id}`, axiosConfig);
      const updatedItems = exportItems.filter((_, i) => i !== index);
      setExportItems(updatedItems);
      message.success('Item deleted successfully');
    } catch (error) {
      message.error('Failed to delete item');
      console.error(error);
    }
  };

  const resetForm = () => {
    setCurrentName('');
    setCurrentDescription('');
    setCurrentImage(null); // Reset image state
    setEditingIndex(null);
    setIsEditing(false);
    setIsModalVisible(false);
  };

  const handleUploadChange = (info) => {
    console.log('Upload Info:', info); // Check the upload info
    if (info.fileList.length > 0) {
      const file = info.fileList[0].originFileObj; // Get the first file
      setCurrentImage(file); // Set the uploaded image
      console.log('Uploaded File:', file); // Log the uploaded file
    } else {
      setCurrentImage(null); // Reset if no file is uploaded
    }
  };
  

  return (
    <div>
      <h2 className="text-3xl font-bold mb-6">Export Items</h2>
      <Button type="primary" onClick={() => setIsModalVisible(true)} style={{ marginBottom: '16px' }}>
        Add Item
      </Button>

      <List
        bordered
        dataSource={exportItems}
        renderItem={(item, index) => (
          <List.Item
            actions={[
              <Button onClick={() => handleEditClick(index)} type="link">
                Edit
              </Button>,
              <Button onClick={() => handleDeleteClick(index)} type="link" danger>
                Delete
              </Button>,
            ]}
          >
            <Typography.Text strong>{item.name}</Typography.Text>
            <Typography.Text type="secondary" style={{ marginLeft: '10px' }}>
              - {item.description}
            </Typography.Text>
            {item.img && (
              <img
                src={item.img}
                alt={item.name}
                style={{ width: 50, height: 50, marginLeft: '10px' }}
              />
            )}
          </List.Item>
        )}
      />

      <Modal
        title={isEditing ? 'Edit Item' : 'Add Item'}
        visible={isModalVisible}
        onCancel={resetForm}
        footer={null}
      >
        <Form
          name="exportItemsForm"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Item Name"
            rules={[{ required: true, message: 'Please input the item name!' }]}
          >
            <Input
              value={currentName}
              onChange={(e) => setCurrentName(e.target.value)}
              placeholder="Enter item name here..."
            />
          </Form.Item>
          <Form.Item
            label="Description"
            rules={[{ required: true, message: 'Please input a description!' }]}
          >
            <Input.TextArea
              value={currentDescription}
              onChange={(e) => setCurrentDescription(e.target.value)}
              rows={4}
              placeholder="Enter a description here..."
            />
          </Form.Item>
          <Form.Item label="Upload Image">
            <Upload
              onChange={handleUploadChange}
              beforeUpload={() => false}
              accept="image/*"
              maxCount={1} // Restrict to one file
            >
              <Button icon={<UploadOutlined />}>Upload Image</Button>
            </Upload>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              {isEditing ? 'Update' : 'Add'} Item
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

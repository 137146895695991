import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { message } from 'antd'; // Assuming you're using Ant Design for message

const VisionMissionValues = () => {
  const [loading, setLoading] = useState(true);
  const [vision, setVision] = useState('');
  const [mission, setMission] = useState('');
  const [coreValues, setCoreValues] = useState([]); // New state for core values

  const axiosConfig = {
    // Your axios configuration here
  };

  useEffect(() => {
    const fetchVisionData = async () => {
      try {
        const visionResponse = await axios.get('api/backgrounds/6718b5283114c9e38bba5779');
        setVision(visionResponse.data.description);
    
        const missionResponse = await axios.get('api/backgrounds/6718d67fcf3fdbfca7bb2645', axiosConfig);
        setMission(missionResponse.data.description);
    
        const coreValuesResponse = await axios.get('api/core-values', axiosConfig);
        console.log('Core Values Response:', coreValuesResponse.data); // Log the response to inspect it
    
        // Check if the response is an array
        if (Array.isArray(coreValuesResponse.data)) {
          setCoreValues(coreValuesResponse.data); // Update state only if it's an array
        } else {
          console.error('Core values data is not an array', coreValuesResponse.data);
          setCoreValues([]); // Set an empty array if the response is invalid
        }
    
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        message.error('Failed to load Vision, Mission, or Core Values description');
        setLoading(false);
      }
    };
    

    fetchVisionData();
  }, []);

  return (
    <section className="bg-gray-100 py-16">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-gray-900">About Us</h2>
        <br />
        <p className="text-xl font-primary mb-6 text-left">
          NGS Global Trading PLC is a premier trading company founded with the vision of connecting Ethiopia’s rich resources with international markets while bringing essential goods from abroad to meet local demands. Established in 2024, the company has quickly grown to become a trusted partner in the trade of various goods, offering unmatched quality and service. Headquartered in Mekelle, Ethiopia, NGS Global Trading operates across the country, as well as in key international markets, making it a well-respected player in both import and export sectors.
          <br /><br />
          The core mission of NGS Global Trading PLC is to bridge the gap between Ethiopian agricultural producers and international buyers, while also ensuring the availability of crucial imports for the local economy. The company’s scope of work is diverse, encompassing the importation of commodities such as rebar, metal billets, sheet metal, aluminum, timber, and food products like wheat, sunflower oil, palm oil, and sugar. This wide range of goods supports various industries including construction, manufacturing, and food processing, which are vital for Ethiopia's growing economy.
          <br /><br />
          In addition to imports, NGS Global Trading is a key exporter of Ethiopia’s finest agricultural products. The company proudly supplies high-quality Ethiopian coffee, sesame, oil seeds, fruits, vegetables, and khat to markets across the globe. By connecting local farmers and producers to international customers, NGS Global is contributing to the growth and global recognition of Ethiopia’s agricultural sector. The company works diligently to uphold high standards in both sourcing and delivering products, ensuring that the unique qualities of Ethiopian produce are appreciated worldwide.
          <br /><br />
          NGS Global Trading PLC will build its foundation of trust, reliability, and efficiency because of its experienced experts. The company leverages a vast network of suppliers, manufacturers, and clients, both locally and internationally, to ensure seamless operations. Its well-organized logistics and supply chain systems enable NGS Global to meet the needs of diverse clients, from government contracts to private sector businesses. Whether dealing with construction materials for large-scale projects or agricultural exports for foreign markets, the company is recognized for its commitment to quality and timely delivery.
          <br /><br />
          Looking ahead, NGS Global Trading PLC is poised for continued growth and expansion. As it strengthens its partnerships and explores new opportunities, the company aims to play an even greater role in Ethiopia’s economic development. With its comprehensive approach to trade and a deep understanding of both local and global markets, NGS Global Trading PLC is well positioned to thrive in the years to come.
        </p>
        <div className="bg-gray-100 md:px-10 px-4 py-12 font-[sans-serif]">
          <div className="max-w-5xl mx-auto">
            <h2 className="text-3xl font-extrabold text-gray-800 mb-8">Vision, Mission, and Core Values</h2>
            <div className="space-y-8">
              {/* Vision Section */}
              <div className="bg-white rounded overflow-hidden">
                <div className="p-6">
                  <h3 className="text-lg font-bold text-gray-800 mb-3">Vision</h3>
                  <p className="text-gray-500 text-sm">{loading ? 'Loading...' : vision}</p>
                </div>
              </div>

              {/* Mission Section */}
              <div className="bg-white rounded overflow-hidden">
                <div className="p-6">
                  <h3 className="text-lg font-bold text-gray-800 mb-3">Mission</h3>
                  <p className="text-gray-500 text-sm">{loading ? 'Loading...' : mission}</p>
                </div>
              </div>

              {/* Core Values Section */}
              <div className="bg-white rounded overflow-hidden">
                <div className="p-6">
                  <h3 className="text-lg font-bold text-gray-800 mb-3">Core Values</h3>
                  {
  Array.isArray(coreValues) && coreValues.length > 0 ? (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-sm:gap-8">
      <ul className="list-disc list-inside">
        {coreValues.map((value, index) => (
          <li key={index} className="text-gray-500 text-sm">
            <strong>{value.name}:</strong> {value.description}
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <div className="text-center text-2xl">Loading</div>
  )
}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VisionMissionValues;

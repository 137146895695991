import axios from 'axios';
import React, { useEffect, useState } from 'react'

export const BlogPosts = () => {
    const [exportItems, setExportItems] = useState([]);

    useEffect(() => {
        const fetchExportedItems = async () => {
          try {
            const response = await axios.get('api/imports');
            
            // Check if the response data is an array
            if (Array.isArray(response.data)) {
              setExportItems(response.data); // Set only if it's an array
            } else {
              console.error('Expected an array but got:', response.data);
              setExportItems([]); // Fallback to an empty array if not an array
            }
    
          } catch (error) {
            console.error(error);
            setExportItems([]); // Fallback to an empty array on error
          }
        };
    
        fetchExportedItems();
      }, []);

      const today = new Date().toLocaleDateString('en-US', {
        day: '2-digit',
        month: 'long',
        year: 'numeric'
      });

  return (
    <div className="p-4 font-[sans-serif] bg-white">
    <div className="max-w-6xl max-lg:max-w-3xl max-sm:max-w-sm mx-auto">
      <div className="max-w-md mx-auto">
        <h2 className="text-3xl font-extrabold text-gray-800 mb-12 text-center leading-10">
          Import Items
        </h2>
      </div>
      {
        exportItems.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-sm:gap-8">
            {exportItems.map((item, index) => (
              <div className="bg-white rounded overflow-hidden" key={index}>
                <img src={item.img} alt={item.title} className="w-full h-52 object-cover" />
                <div className="p-6">
                  <h3 className="text-lg font-bold text-gray-800 mb-3">{item.name}</h3>
                  <p className="text-gray-500 text-sm">{item.description}</p>
                  <p className="text-gray-800 text-[13px] font-semibold mt-4">{today}</p>
                  <a href={item.link || "#"} className="mt-4 inline-block px-4 py-2 rounded tracking-wider bg-green-500 hover:bg-green-600 text-white text-[13px]">
                    Read More
                  </a>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center text-2xl">Loading</div>
        )
      }
    </div>
  </div>
  )
}

import React, { useState, useEffect } from 'react';
import {
  FileTextOutlined, // For Background
  ImportOutlined,    // For Import
  ExportOutlined,    // For Export
  EyeOutlined,       // For Vision
} from '@ant-design/icons';
import img1 from './assets/logo.png';
import { Breadcrumb, Layout, Menu, theme } from 'antd';

import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom'; // Import react-router-dom
import { Vision } from './Component/Vision';
import { Mission } from './Component/Mission';
import { Core_Values } from './Component/Core_Values';
import { Imported_Items } from './Component/Imported_Items';
import { Export } from './Component/Export';
import { Not_Founded } from './Component/Not_Founded';
import Login from './Component/Login';
import { Main } from './Component/Main/Main';


const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem('Background', 'sub1', <FileTextOutlined />, [
    getItem('Vision', '/', <EyeOutlined />),
    getItem('Mission', '/mission', <EyeOutlined />),
    getItem('Core Values', '/core-values', <EyeOutlined />),
  ]),
  getItem('Import', '/import', <ImportOutlined />),
  getItem('Export', '/export', <ExportOutlined />),
  getItem('Logout', '6'),
];

function Root() {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    setIsLoggedIn(!!accessToken);
  }, []);

  const handleMenuClick = (e) => {
    if (e.key === '6') {
      localStorage.removeItem('accessToken');
      setIsLoggedIn(false);
      navigate('/login'); // Redirect to the login page after logout
    }

    else{
      navigate(e.key);
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {isLoggedIn ? (
        <>
          <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
            <div className="demo-logo-vertical flex justify-center items-center text-white h-24">
              <img className="max-w-full max-h-full" src={img1} alt="logo" />
            </div>

            <Menu
              theme="dark"
              defaultSelectedKeys={['3']}
              mode="inline"
              items={items}
              onClick={handleMenuClick}
            />
          </Sider>

          <Layout>
            <Header style={{ padding: 0, background: colorBgContainer }} />
            <Content style={{ margin: '0 16px' }}>
              <div
                style={{
                  padding: 24,
                  minHeight: 360,
                  background: colorBgContainer,
                  borderRadius: borderRadiusLG,
                }}
              >
                <Routes>
                  <Route path="/" element={<Vision />} />
                  <Route path="/mission" element={<Mission />} />
                  <Route path="/core-values" element={<Core_Values />} />
                  <Route path="/import" element={<Imported_Items />} />
                  <Route path="/export" element={<Export />} />
                  <Route path="*" element={<Not_Founded />} />
                </Routes>
              </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
              Ant Design ©{new Date().getFullYear()} Created by Ant UED
            </Footer>
          </Layout>
        </>
      ) : (
        
           
              <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/" element={<Main />} />
                  <Route path="*" element={<Not_Founded />} />
                </Routes>
           
          
      )}
    </Layout>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Root />
    </BrowserRouter>
  );
}

export default App;

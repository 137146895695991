import React, { useState, useEffect } from 'react';
import { Button, Form, Input, List, Typography, message, Modal, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

export const Imported_Items = () => {
  const [importedItems, setImportedItems] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentName, setCurrentName] = useState('');
  const [currentDescription, setCurrentDescription] = useState('');
  const [currentImage, setCurrentImage] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const accessToken = localStorage.getItem('accessToken');
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  useEffect(() => {
    const fetchImportedItems = async () => {
      try {
        const response = await axios.get('api/imports', axiosConfig);
        setImportedItems(response.data);
        
      } catch (error) {
        message.error('Failed to load imported items');
        console.error(error);
      }
    };

    fetchImportedItems();
  }, [accessToken]);

  const onFinish = async () => {
    try {
      const formData = new FormData();
      formData.append('name', currentName);
      formData.append('description', currentDescription);
      if (currentImage) {
        formData.append('img', currentImage); // Append the file if it exists
      }

      if (isEditing && editingIndex !== null) {
        const id = importedItems[editingIndex]._id;
        await axios.put(`api/imports/${id}`, formData, {
          ...axiosConfig,
          headers: {
            ...axiosConfig.headers,
            'Content-Type': 'multipart/form-data',
          },
        });

        const updatedItems = [...importedItems];
        updatedItems[editingIndex] = { ...updatedItems[editingIndex], name: currentName, description: currentDescription };
        setImportedItems(updatedItems);
        message.success('Item updated successfully');
      } else {
        const response = await axios.post('api/imports', formData, {
          ...axiosConfig,
          headers: {
            ...axiosConfig.headers,
            'Content-Type': 'multipart/form-data',
          },
        });
        setImportedItems([...importedItems, response.data]);
        message.success('Item added successfully');
      }
    } catch (error) {
      message.error('Failed to save item');
      console.error(error);
    }

    resetForm();
  };

  const handleEditClick = (index) => {
    setCurrentName(importedItems[index].name);
    setCurrentDescription(importedItems[index].description);
    setCurrentImage(null); // Reset image for editing
    setEditingIndex(index);
    setIsEditing(true);
    setIsModalVisible(true);
  };

  const handleDeleteClick = async (index) => {
    try {
      const id = importedItems[index]._id;
      await axios.delete(`api/imports/${id}`, axiosConfig);
      const updatedItems = importedItems.filter((_, i) => i !== index);
      setImportedItems(updatedItems);
      message.success('Item deleted successfully');
    } catch (error) {
      message.error('Failed to delete item');
      console.error(error);
    }
  };

  const resetForm = () => {
    setCurrentName('');
    setCurrentDescription('');
    setCurrentImage(null); // Reset image state
    setEditingIndex(null);
    setIsEditing(false);
    setIsModalVisible(false);
  };

  const handleUploadChange = (info) => {
    if (info.fileList.length > 0) {
      const file = info.fileList[0].originFileObj; // Get the first file
      setCurrentImage(file); // Set the uploaded image
    } else {
      setCurrentImage(null); // Reset if no file is uploaded
    }
  };

  return (
    <div>
      <h2 className="text-3xl font-bold mb-6">Imported Items</h2>
      <Button type="primary" onClick={() => setIsModalVisible(true)} style={{ marginBottom: '16px' }}>
        Add Item
      </Button>

      <List
        bordered
        dataSource={importedItems}
        renderItem={(item, index) => (
          <List.Item
            actions={[
              <Button onClick={() => handleEditClick(index)} type="link">
                Edit
              </Button>,
              <Button onClick={() => handleDeleteClick(index)} type="link" danger>
                Delete
              </Button>,
            ]}
          >
            <Typography.Text strong>{item.name}</Typography.Text>
            <Typography.Text type="secondary" style={{ marginLeft: '10px' }}>
              - {item.description}
            </Typography.Text>
            {item.img && (
              <img
                src={item.img}
                alt={item.name}
                style={{ width: 50, height: 50, marginLeft: '10px' }}
              />
            )}
          </List.Item>
        )}
      />

      <Modal
        title={isEditing ? 'Edit Item' : 'Add Item'}
        visible={isModalVisible}
        onCancel={resetForm}
        footer={null}
      >
        <Form
          name="importedItemsForm"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Item Name"
            rules={[{ required: true, message: 'Please input the item name!' }]}
          >
            <Input
              value={currentName}
              onChange={(e) => setCurrentName(e.target.value)}
              placeholder="Enter item name here..."
            />
          </Form.Item>
          <Form.Item
            label="Description"
            rules={[{ required: true, message: 'Please input a description!' }]}
          >
            <Input.TextArea
              value={currentDescription}
              onChange={(e) => setCurrentDescription(e.target.value)}
              rows={4}
              placeholder="Enter a description here..."
            />
          </Form.Item>
          <Form.Item label="Upload Image">
            <Upload
              onChange={handleUploadChange}
              beforeUpload={() => false} // Prevent automatic upload
              accept="image/*"
              maxCount={1} // Restrict to one file
            >
              <Button icon={<UploadOutlined />}>Upload Image</Button>
            </Upload>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              {isEditing ? 'Update' : 'Add'} Item
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

import React from 'react'
import Navbar from './Navbar/Navbar'
import Banner from './Banner/Banner'
import VisionMissionValues from './VisionMissionValues/VisionMissionValues'
import Banner2 from './Banner/Banner2'

import Services from './Services/Services'
import WhyChooseUs from './WhyChooseUs/WhyChooseUs'
import ContactUs from './ContactUs/ContactUs'
import Footer from './Footer/Footer'
import { BlogPosts } from './BlogPosts/BlogPosts'


export const Main = () => {
  return (
    <div className="bg-primary">
        
    <div className="">
      
      <Navbar />
      <Banner />
      <VisionMissionValues />
      <Banner2 />

      <BlogPosts />
      <Services />
      <WhyChooseUs />
      <ContactUs />
      <br></br>
      <Footer />
    </div>
    
  </div>
  )
}

import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image1 from "../../../assets/banner/car.png";
import Image2 from "../../../assets/banner/img3.jpg";
import Image3 from "../../../assets/banner/img4.jpg";

const ImageList = [
  {
    id: 1,
    img: Image1,
    title: "Welcome To NGS Global Trading",
    description:
      "We Import And Export High-Quality Products, Offering The Best Selection For Our Customers Worldwide.",
  },
  {
    id: 2,
    img: Image2,
    title: "Explore Our Exclusive Range",
    description:
      "Discover A Diverse Range Of Imported Goods, Carefully Selected To Meet Your Needs.",
  },
  {
    id: 3,
    img: Image3,
    title: "Quality Products, Global Reach",
    description:
      "From Import To Export, We Ensure Exceptional Products Reach You Wherever You Are.",
  },
];

const Banner = ({ handleOrderPopup }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true, // Enables looping of slides
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enables automatic slide movement
    autoplaySpeed: 1000, // Slide changes every 3 seconds
    afterChange: (current) => setCurrentSlide(current),
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
    sliderRef.slickGoTo(index); // Navigate to the specific slide
  };

  let sliderRef;

  return (
    <div className="relative w-full">
      <Slider ref={(slider) => (sliderRef = slider)} {...settings} className="w-full">
        {ImageList.map((data) => (
          <div
            key={data.id}
            className="relative h-screen flex justify-center items-center"
          >
            <div
              className="absolute inset-0"
              style={{
                backgroundImage: `url(${data.img})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                opacity: 0.7,
              }}
            ></div>
            <div className="absolute inset-0 flex flex-col justify-center items-center text-center text-white px-6">
              {/* Updated Title Color to Blue */}
              <h1
                className="text-5xl sm:text-6xl lg:text-7xl font-bold mb-4"
                style={{
                  color: "blue", // Title color is now blue
                  textShadow: "2px 2px 10px rgba(0, 0, 0, 0.3)",
                }}
              >
                {data.title}
              </h1>
              {/* Updated Description with Color and Shadow */}
              <p
                className="text-lg sm:text-xl lg:text-2xl max-w-2xl"
                style={{
                  color: "#f8f9fa",
                  textShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                }}
              >
                {data.description}
              </p>
              {/* Order Button with Enhanced Hover Effect */}
              <button
                onClick={handleOrderPopup}
                className="mt-6 bg-gradient-to-r from-primary to-gold hover:scale-105 transition-transform duration-200 text-white px-6 py-2 rounded-full shadow-lg hover:shadow-xl"
              >
                Order Now
              </button>
            </div>
          </div>
        ))}
      </Slider>
      {/* Slide Indicator */}
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {ImageList.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`w-3 h-3 rounded-full ${currentSlide === index ? 'bg-white' : 'bg-gray-400'}`}
          />
        ))}
      </div>
      {/* Left Arrow */}
      <div className="absolute top-1/2 left-4 transform -translate-y-1/2 z-10">
        <button
          onClick={() => goToSlide((currentSlide - 1 + ImageList.length) % ImageList.length)}
          className="bg-white text-black p-2 rounded-full shadow-lg hover:bg-gray-200"
        >
          &#10094; {/* Left arrow */}
        </button>
      </div>
      {/* Right Arrow */}
      <div className="absolute top-1/2 right-4 transform -translate-y-1/2 z-10">
        <button
          onClick={() => goToSlide((currentSlide + 1) % ImageList.length)}
          className="bg-white text-black p-2 rounded-full shadow-lg hover:bg-gray-200"
        >
          &#10095; {/* Right arrow */}
        </button>
      </div>
    </div>
  );
};

export default Banner;

import React, { useState, useEffect } from 'react';
import { Button, Form, Input, List, Typography, message, Modal } from 'antd';
import axios from 'axios';

export const Core_Values = () => {
  const [coreValues, setCoreValues] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentName, setCurrentName] = useState('');
  const [currentDescription, setCurrentDescription] = useState('');
  const [editingIndex, setEditingIndex] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const accessToken = localStorage.getItem('accessToken');

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  useEffect(() => {
    const fetchCoreValues = async () => {
      try {
        const response = await axios.get('api/core-values');
        setCoreValues(response.data);
        console.log('Core Values:', response.data);
      } catch (error) {
        message.error('Failed to load core values');
        console.error(error);
      }
    };

    fetchCoreValues();
  }, [accessToken]);

  const onFinish = async () => {
    try {
      const updatedValue = {
        name: currentName,
        description: currentDescription,
      };

      if (isEditing && editingIndex !== null) {
        const id = coreValues[editingIndex]._id;
        await axios.put(`api/core-values/${id}`, updatedValue, axiosConfig);

        const updatedValues = [...coreValues];
        updatedValues[editingIndex] = { ...updatedValues[editingIndex], ...updatedValue };
        setCoreValues(updatedValues);
        message.success('Core value updated successfully');
      } else {
        const response = await axios.post('api/core-values', updatedValue, axiosConfig);
        setCoreValues([...coreValues, response.data]);
        message.success('Core value added successfully');
      }
    } catch (error) {
      message.error('Failed to save core value');
      console.error(error);
    }

    resetForm();
  };

  const handleEditClick = (index) => {
    setCurrentName(coreValues[index].name);
    setCurrentDescription(coreValues[index].description);
    setEditingIndex(index);
    setIsEditing(true);
    setIsModalVisible(true);
  };

  const handleAddClick = () => {
    resetForm();
    setIsModalVisible(true);
  };

  const handleDeleteClick = async (index) => {
    try {
      const id = coreValues[index]._id;
      await axios.delete(`api/core-values/${id}`, axiosConfig);
      const updatedValues = coreValues.filter((_, i) => i !== index);
      setCoreValues(updatedValues);
      message.success('Core value deleted successfully');
    } catch (error) {
      message.error('Failed to delete core value');
      console.error(error);
    }
  };

  const resetForm = () => {
    setCurrentName('');
    setCurrentDescription('');
    setEditingIndex(null);
    setIsEditing(false);
    setIsModalVisible(false);
  };

  return (
    <div>
      <h2 className="text-3xl font-bold mb-6">Core Values</h2>
      <Button type="primary" onClick={handleAddClick} style={{ marginBottom: '16px' }}>
        Add Core Value
      </Button>

      <List
        bordered
        dataSource={coreValues}
        renderItem={(item, index) => (
          <List.Item
            actions={[
              <Button onClick={() => handleEditClick(index)} type="link">
                Edit
              </Button>,
              <Button onClick={() => handleDeleteClick(index)} type="link" danger>
                Delete
              </Button>,
            ]}
          >
            <Typography.Text strong>{item.name}</Typography.Text>
            <Typography.Text type="secondary" style={{ marginLeft: '10px' }}>
              - {item.description}
            </Typography.Text>
          </List.Item>
        )}
      />

      <Modal
        title={isEditing ? 'Edit Core Value' : 'Add Core Value'}
        visible={isModalVisible}
        onCancel={resetForm}
        footer={null}
      >
        <Form
          name="coreValuesForm"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Core Value"
            rules={[{ required: true, message: 'Please input a core value!' }]}
          >
            <Input
              value={currentName}
              onChange={(e) => setCurrentName(e.target.value)}
              placeholder="Enter a core value here..."
            />
          </Form.Item>
          <Form.Item
            label="Description"
            rules={[{ required: true, message: 'Please input a description!' }]}
          >
            <Input.TextArea
              value={currentDescription}
              onChange={(e) => setCurrentDescription(e.target.value)}
              rows={4}
              placeholder="Enter a description here..."
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              {isEditing ? 'Update' : 'Add'} Core Value
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

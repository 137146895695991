import React from 'react';
import { Button, Form, Input } from 'antd';
import axios from 'axios'; // Import Axios
import logo from '../assets/logo.png';

export const Login = () => {
    const onFinish = async (values) => {
        try {
            const response = await axios.post('api/users/login', { // Correct URL
                email: values.email,
                password: values.password,
            });

            console.log('Login Successful:', response.data);
            // Store the access token in local storage
            localStorage.setItem('accessToken', response.data.accessToken);
            
            // Redirect or perform other actions after successful login
            window.location.href = '/'; // Redirect to dashboard
        } catch (error) {
            console.error('Login Failed:', error);
            // Handle login failure, e.g., display an error message
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100"> {/* Added flex-col to stack items vertically */}
            <img className="w-32 h-32 mb-8" src={logo} alt="logo" /> {/* Set width and height and added margin bottom */}
            <Form
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                style={{
                    maxWidth: 600,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Email" // Changed label to Email
                    name="email" // Changed input name to email
                    rules={[
                        {
                            required: true,
                            message: 'Please input your email!', // Changed validation message
                            type: 'email', // Added type for email validation
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Login;
